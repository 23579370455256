<template>
  <div class="page">
  		<webHeader :currentIndex="currentIndex" :themes="'white'"></webHeader>
		<div class="pageLogin">
			<div class="imgArea">
				<img class="image" src="../../../assets/web/img/loginBg.png">
			</div>
			<div class="form">
				<div class="title">{{$t('webTitle.login')}}</div>
				<van-field v-model="loginform.username" clearable :placeholder="$t('register.enterusername')" class="input" >
				</van-field>
				<van-field v-model="loginform.password" :type="type" right-icon="closed-eye" :placeholder="$t('login.password')" @click-right-icon="openeye" class="input" >
				</van-field>
				<div class="loginTvTo" @click="login()" v-if="loginform.username && loginform.password && loginform.password.length >= 6" >
				  {{ $t("login.login") }}
				</div>
				<div class="loginTv" v-else>
				  {{ $t("login.login") }}
				</div>
				<div class="footer">
					<span class="pointer" @click="register()">{{ $t("login.register") }}</span>
				</div>
			</div>
			
		</div>
  </div>
</template>
<script>
import webHeader from "@/components/web/webHeader.vue";
import webFooter from "@/components/web/webFooter.vue";
export default {
  components: { webHeader, webFooter},
  data() {
    return {
	  currentIndex:7,
      lang: localStorage.getItem("lang") || "en",
	  loginform: {
	    username: "",
	    password: "",
	  },
	  disabled:true,
	  serverurl:'',
	  type: "password",
    };
  },
  created() {
	if(!localStorage.getItem("lang")) {
		localStorage.setItem("lang",'en')
		localStorage.setItem("language",'en')
	}
    this.getserver();
  },
  mounted() {
  	let that = this;
	window.addEventListener("keydown", function(event) {
		//收到标准键盘事件keydown后，创建一个自定义事件my-key-down, 并dispatchEvent
		//Vue绑定的@my-key-down就会收到这个自定义事件
		if(event.keyCode==13){
			that.login();
		};

	})
  },
  methods: {
	openeye(event) {
	  if (this.type === "password") {
	    event.target.className = "van-icon van-icon-eye";
	    this.type = "text";
	  } else {
	    event.target.className = "van-icon van-icon-closed-eye";
	    this.type = "password";
	  }
	},
    // 获取客服链接
    async getserver() {
      const { data } = await this.$http.get("/home/index/serviceurl");
      if (data) {
        if (data.code === 200) {
          this.serverurl = data.data;
        }
      }
    },
	enterLogin(){
		document.onkeydown = e =>{
			//13表示回车键，为了更严谨，也可以加别的，可以打印e看一下
			if (e.keyCode === 13) {
				//回车后执行方法
				this.login()
			}
		}
	},
    // 登录
    async login() {
		
      if (this.loginform.username === "" || this.loginform.password === "") {
        this.$toast.fail(this.$t("login.complete"));
        return false;
      }
	  this.disabled = false;
      const { data } = await this.$http.post(
        "/home/index/login",
        this.loginform
      );
      if (data) {
        if (data.code === 200) {
          console.log(data.data.token);
          window.localStorage.setItem("token", data.data.token);
          this.$router.push("/web/index/index");
        } else {
          this.$toast.fail(this.$t(data.msg));
        }
      }
    },
    // 用户协议
    agreement() {
      this.$router.push({ path: "/web/index/agreement" });
    },
    // 忘记密码
    forget() {
      this.$router.push({ path: "/web/index/forget" });
    },
    // 快速注册
    register() {
      this.$router.push({ path: "/web/index/register" });
    },
    onLanguage(item) {
      let lang = item.value;
      this.$i18n.locale = lang;
      this.lang = lang;
      localStorage.setItem("lang", lang);
      this.showLang = false;
    },
    // 客服页面
    toserver() {
      window.location.href = this.serverurl;
    },
  },
};
</script>

<style lang="less" scoped>
.page{ min-height: 100vh;}
.pageLogin{ width: 1130px; margin:0 auto; display: flex; justify-content: space-between;
	.imgArea{ width: 500px; height: 500px; margin-top: 50px;
		.image{width: 100%; height: 100%;}
	}
	.form{ margin-top: 80px;width:398px;
		.title{ font-size: 36px;margin: 30px 0 0 0;color: #000; text-align: left;}
		.input{ margin-top:10px; width: 100%; height:56px; line-height: 56px; background: none; border:none; border-bottom:1px solid #ddd;
			&:hover{ border-bottom:1px solid #000;}
		}
		.loginTvTo {margin-top: 16px;width: 100%;height: 46px; line-height: 46px; outline:none; border:none; cursor: pointer; text-align: center; color:#fff;background-color: #f0b90b;display: block;border-radius:6px;}
		.loginTv {margin-top: 16px;width: 100%;height: 46px; line-height: 46px; outline:none; border:none; cursor: pointer; text-align: center; color:#fff;background-color: #f2d169;display: block;border-radius:6px;}
		.button{ background-color: #f0b90b; height: 46px; line-height: 46px; outline:none; border:none; cursor: pointer; text-align: center; color:#fff; margin-top:16px; display: block; width: 100%; border-radius:6px;}
		.footer{ line-height: 48px; color:#000; font-size: 14px;}
	}
}
</style>
